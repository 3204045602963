<template>
  <v-container
    id="role-edit"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="12"
      >
        <material-card
          color="primary"
          icon="mdi-account-outline"
        >
          <template #title>
            {{ isEditMode ? 'Edit Role' : 'New Role' }}
          </template>

          <v-form>
            <v-container class="py-0">
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-text-field
                    v-model="item.name"
                    color="purple"
                    label="Name *"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="12"
                >
                  <v-card flat>
                    <v-card-text>
                      <v-container fluid>
                        <v-row>
                          <v-col
                            v-for="permission in permissions"
                            :key="permission.id"
                            cols="12"
                            sm="4"
                            md="4"
                          >
                            <v-checkbox
                              v-model="activePermissions"
                              :label="permission.name"
                              color="red"
                              :value="permission.id"
                              hide-details
                            ></v-checkbox>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
          <v-container class="py-0">
            <v-row>
              <v-col
                cols="12"
                class="text-right"
              >
                <v-btn
                  color="primary"
                  min-width="150"
                  @click="submit"
                >
                  {{ isEditMode ? 'Save Role' : 'Create Role' }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  export default {
    name: 'RoleItemEditForm',
    data: () => ({
      loading: false,
      item: {
        name: '',
        permissions: [],
      },
      activePermissions: [],
    }),
    computed: {
      ...mapGetters({
        permissions: 'permissions/getPermissions',
      }),
      isEditMode () {
        return !!this.$route.params.id
      },
    },
    mounted () {
      if (this.isEditMode) {
        this.loadItem({ role_id: this.$route.params.id })
          .then(result => {
            this.item.name = result.data.name
            this.item.permissions = result.data.permissions
            this.item.permissions.forEach(p => {
              this.activePermissions.push(p.id)
            })
            this.loadPermissions()
          })
      }
    },
    methods: {
      ...mapActions({
        loadItem: 'roles/loadRole',
        create: 'roles/create',
        update: 'roles/update',
        loadPermissions: 'permissions/loadPermissions',
      }),
      submit () {
        this.loading = true
        if (this.isEditMode) {
          this.update({
            role_id: this.$route.params.id,
            ...this.item,
            permissions: this.activePermissions,
          })
            .finally(() => {
              this.loading = false
            })
        } else {
          this.create({
            ...this.item,
            permissions: this.activePermissions,
          })
            .finally(() => {
              this.loading = false
            })
        }
      },
    },
  }
</script>
